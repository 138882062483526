<template>
    <div class="d-flex flex-column h-100">
      <b-container fluid>
        <b-row v-if="tableType == 'group'">
          <b-col lg="6">
              <!-- <div v-if="!shareGroups" class="d-flex justify-content-center mt-5">
                <b-spinner variant='primary' style='width: 3rem; height: 3rem;'></b-spinner>
              </div> -->
              <div v-if="shareGroups && projectCompany">
                <b-input-group class="my-0">
                  <b-dropdown class="dropdown dropdown-canvas outline-primary bg-white border-white text-dark my-0" :disabled="!hasShareGroups" :text="selectedGroupName" variant="light">
                    <b-dropdown-item-button
                      class="dropdown-button"
                      variant="outline-secondary"
                      v-for="(group, index) in shareGroups"
                      :key="index"
                      @click="selectGroup(group)"
                    >
                      {{ group.group_name }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                  <b-button :disabled="isLoading || !selectedGroup" @click="addGroup(selectedGroup)" variant="primary">
                    <b-spinner v-if='isLoading' class='spinner' small>
                    </b-spinner>
                    {{isLoading ? 'Adding' : 'Add'}}
                    </b-button>
                </b-input-group>
              </div>
          </b-col>
        </b-row>

        <b-row v-else-if="tableType == 'user'">
          <b-col lg="6" >
            <b-form-group label="Share link to:" class="no-top-margin-all-descendants mt-1">
              <b-form-radio-group
                class="mt-0"
                v-model="selectedShareToOption"
                :options="shareToOptions"
              ></b-form-radio-group>
            </b-form-group>
            <b-input-group prepend="Email">
              <b-form-input v-model="selectedUserEmail"></b-form-input>
              <b-input-group-append class="my-0">
                <b-button :disabled="isLoading || !selectedUserEmail || isDisabled" variant="primary" @click="addUser(selectedUserEmail)">
                  <b-spinner v-if='isLoading' class='spinner' small>
                  </b-spinner>
                  {{isLoading ? 'Sharing' : 'Share'}}
                  </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-table
          head-variant="light"
          class='border'
          style='font-size: 0.875rem;'
          sticky-header
          hover
          small
          :fields="tableFields"
          :items="tableItems"
          :filter="filter"
          :sort-by.sync="sortBy">
          <template v-if="tableType == 'user'" v-slot:cell(re_share)="row">
          <b-spinner small v-if='changingSharePermission == row.item'></b-spinner>
            <b-form-checkbox
              class="no-top-margin-all-descendants pl-1"
              inline
              @input="changeSharePermission($event, row.item)"
              v-else
              v-model="row.item.re_share"
              name="check-button">
            </b-form-checkbox>
          </template>
          <template v-slot:cell(delete)="row">
            <b-spinner v-if='isDeleting == row.item' class='mr-3' small>
            </b-spinner>
            <b-icon v-else-if="row.item.id == loggedInUser.id"
              icon='dash'
              class="non-delete-icon">
            </b-icon>
            <b-icon v-else
              icon='trash'
              role='button'
              class="delete-icon"
              @click="deleteProjectShare(row.item, tableType)">
            </b-icon>
          </template>
          <template v-slot:cell(default_for_new_projects)="row">
            <b-icon v-if="row.item.default_for_new_projects"
              class="check-icon"
              icon='check'>
            </b-icon>
            <b-icon v-else
              class="check-icon"
              icon='dash'>
            </b-icon>
          </template>
        </b-table>

      </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from '@/network/eventbus';
import {
  BTable,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BInputGroup,
  BFormInput,
  BInputGroupAppend
} from 'bootstrap-vue';
export default {
  name: 'ShareTable',
  components: {
    BTable,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    tableType: {
      required: true
    },
    tableData: {
      required: true
    },
    tableFields: {
      required: true
    }
  },
  computed: {
    projectCompany() {
      return this.selectedProject?.company;
    },
    projectId() {
      return Number(this.$route.params.id);
    },
    shareGroups() {
      return this.tableData.choices;
    },
    projectShares() {
      return this.$store.getters['project/projectShares'];
    },
    tableItems() {
      return this.tableData.added;
    },
    isDisabled() {
      return (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.selectedUserEmail)) == false;
    },
    hasShareGroups(){
      if (this.shareGroups)
        return Object.keys(this.shareGroups).length;
      else
        return 0;
    },
    ...mapGetters(['loggedInUser']),
    ...mapGetters('project', ['selectedProject', 'projectShares', 'createProjectShareError', 'updateProjectShareError', 'deleteProjectShareError', 'createUserInviteError'])
  },
  methods: {
    selectGroup(group) {
      this.selectedGroupName = group.group_name;
      this.selectedGroup = group;
    },
    async changeSharePermission(event, user_or_group) {
      this.changingSharePermission = user_or_group;
      // in the case of user invites, check if user field is populated first, then fall back to user invite
      const projectShare = this.projectShares.filter(projectShare => projectShare.user?.id == user_or_group.id)[0] ||
        this.projectShares.filter(projectShare => projectShare.user_invite?.id == user_or_group.id)[0];
      const payload = {
        projectId: this.projectId,
        projectShareId: projectShare.id,
        newValues: {
          're_share': event
        }
      };
      await this.$store.dispatch('project/updateProjectShare', payload);
      this.changingSharePermission = null;

      if (this.updateProjectShareError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: `Failed to update the share permission on project for ${user_or_group.email}`
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: `Project share permission successfully updated for ${user_or_group.email}`
        });
      }
    },
    async addGroup(group) {
      if (group == null) {
        return;
      }
      this.isLoading = true;
      const payload = {
        projectId: this.projectId,
        values: {
          share_group: group.id,
          re_share: false,
          share_status: 'ACCEPTED'
        }
      };
      this.selectedGroupName = '';
      await this.$store.dispatch('project/createProjectShare', payload);
      this.selectedGroupName = 'Please Select';
      this.selectedGroup = null;
      await this.$store.dispatch('project/getProjectShares', this.projectId);
      this.isLoading = false;
      if (this.createProjectShareError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: `Failed to share project with ${group.group_name} group`
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: `Project shared successfully with ${group.group_name} group`
        });
      }
    },
    async addUser(selectedUserEmail) {
      if (selectedUserEmail == null) {
        return;
      }
      this.isLoading = true;
      const payload = {
        projectId: this.projectId,
        values: {
          email: selectedUserEmail,
          share_content: this.selectedShareToOption
        }
      };
      await this.$store.dispatch('project/createUserInvite', payload);
      await this.$store.dispatch('project/getProjectShares', this.projectId);
      this.isLoading = false;
      if (this.createUserInviteError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: `Failed to send project share invite to ${selectedUserEmail}`
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: `Project share invite successfully sent to ${selectedUserEmail}`
        });
      }
    },
    async deleteProjectShare(user_or_group, tableType) {
      this.isDeleting = user_or_group;
      // in the case of user invites, check if user field is populated first, then fall back to user invite
      const project_share = {
        'group': this.projectShares.filter(projectShare => projectShare.share_group?.id == user_or_group.id)[0],
        'user': this.projectShares.filter(projectShare => projectShare.user?.id == user_or_group.id)[0] ||
          this.projectShares.filter(projectShare => projectShare.user_invite?.id == user_or_group.id)[0]
      };
      const payload = {
        projectId: this.projectId,
        projectShareId: project_share[tableType].id
      };
      await this.$store.dispatch('project/deleteProjectShare', payload);
      await this.$store.dispatch('project/getProjectShares', this.projectId);
      this.isDeleting = null;
      const deletedUserOrGroup = user_or_group.group_name || user_or_group.username || user_or_group.email;
      if (this.deleteProjectShareError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: `Failed to revoke project share for ${deletedUserOrGroup}`
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: `Project share successfully revoked for ${deletedUserOrGroup}`
        });
      }
    }
  },
  data() {
    return {
      sortBy: 'id',
      selectedGroupName: 'Please Select',
      selectedUserEmail: null,
      selectedGroup: null,
      selectedShareToOption: 'PROJECT',
      fields: [],
      filter: null,
      isLoading: false,
      isDeleting: null,
      changingSharePermission: null,
      shareToOptions: [{
        text: 'project', value: 'PROJECT'
      }, {
        text: 'insights', value: 'INSIGHTS'
      }]
    };
  }
};
</script>

<style scoped>
.dropdown{
  min-width: 50%;
}
</style>

<style>
.spinner {
  margin-bottom: 0.2em;
}

.delete-icon{
  margin-right: 0.9em;
  cursor: pointer;
}

.non-delete-icon{
  margin-right: 0.9em;
  cursor: default;
}

.check-icon{
  margin: 0;
  cursor: default;
}

.dropdown-canvas /deep/ .dropdown-menu {
  overflow-y: scroll;
  max-height: 37.5rem;
}

.dropdown-button {
  width: calc(100% - 3rem);
}
</style>
